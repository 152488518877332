import { IUser as IUserObject } from 'javascripts/general'

export interface IOrganization {
  title: string
  alliesAttributesFeatureEnabled: boolean
  proxyUploadEnabled: boolean
  showUnknownAffiliationModal: boolean
  shareBadgeEnabled: boolean
}

export enum AudienceNotificationScope {
  ALL_SUBSCRIBERS = 0,
  SUBSET_SUBSCRIBERS = 1,
  NOBODY = 2,
}

export interface Audience {
  id: number
  object_type: 'Event' | 'Call'
  object_id: number
  audience_scope?: AudienceNotificationScope
  is_csv_import?: boolean
  audience_filters?: string
  csv_import_file?: string
  curr_csv_file_upload_details?: string
}

export interface AgendaItem {
  id: number
  event_id: number
  name: string
  time: string
  duration: number
  show_on_invitation: boolean
  notes: string
  created_at: string
  updated_at: string
  test?: string
}

export interface TFileResource {
  id: string
  metadata: {
    filename: string
    mime_type: string
    size: number
  }
  storage: string
}

export interface AudienceImport {
  id: number
  audience_id: number
  added_rows: number
  error_rows: number
  skipped_rows: number
  warning_rows: number
  total_rows: number
  file_data: TFileResource
}

export type TCommunitySubscriberNotificationFrequency =
  | 'every_update'
  | 'no_updates'

export type TCommunitySubscriberAllyAffiliate =
  | 'no_designation'
  | 'ally'
  | 'affiliate'

export const toAllyAffiliate = (
  value: string,
): TCommunitySubscriberAllyAffiliate => {
  switch (value) {
    case 'ally':
    case 'affiliate':
    case 'no_designation':
      return value
    default:
      return 'no_designation'
  }
}

//interfaces
export const communitiesTypes = ['cohorts', 'communities'] as const
export const communityTypes = ['cohort', 'community'] as const
export type TCommunityType = (typeof communityTypes)[number]
export type TCommunitiesType = (typeof communitiesTypes)[number]
export interface ICommunity {
  id: number
  logo: string
  thumbnail: string
  name: string
  subtitle: string
  allies_welcome: boolean
  link: string
  subscribed: boolean
  invitable: boolean
  type?: TCommunityType
  leaders?: ITeamMember[]
  resources?: Resource<ResourceType>[]
}

export type TStatus = 'closed' | 'deadline' | 'spots'

export type TMentorshipExchangeMatchingStatus =
  | 'not_started'
  | 'processing'
  | 'processing_initiated'
  | 'complete'

export enum MentorshipExchangeStatus {
  'scheduled' = 'Scheduled',
  'application_open' = 'Applications open',
  'application_extended' = 'Extended',
  'application_complete' = 'Ready for sessions',
  'application_closed' = 'Applications closed',
  'about_to_open' = 'About to open',
}

export type TMentorshipExchangeStatus = keyof typeof MentorshipExchangeStatus

export enum MentorshipExchangeNotificationStatus {
  'never_notified' = '',
  'notifying' = 'Notifying participants',
  'notified' = 'Notified participants',
  'reopened' = 'Reopened matching',
}
export type TMentorshipExchangeNotificationStatus =
  keyof typeof MentorshipExchangeNotificationStatus

export enum MentorshipProgramMatchNotificationStatus {
  'pending' = 'Pending',
  'notified' = 'Notified',
}
export type TMentorshipProgramMatchNotificationStatus =
  keyof typeof MentorshipProgramMatchNotificationStatus

export type TPillSize = 'small' | 'large'

export enum ConnectorStatus {
  Active = 'active',
  Failed = 'failed',
}

export interface Connector {
  id: number
  label: string
  status: ConnectorStatus
  created_at: string
  uuid: string
  edit_url: string
  webhook_url: string
  change_logs_url: string
  operational_logs_url: string
}

export enum OperationalLogStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum OperationalLogType {
  MSFT_SEND_CARD = 'Send card',
}

export enum OperationalLogCardType {
  CALL = 'call_notify',
  EVENT = 'event_notify',
  WELCOME = 'welcome',
}

export enum UserImportStatus {
  Error = 'error',
  Completed = 'completed',
  In_Progress = 'in progress',
}

export type TEventCallType = 'event' | 'call'

export interface IEventCall {
  id: number
  logo?: string
  date?: string
  datetime: string
  link: string
  title: string
  subtitle?: string
  location: string
  full_location: string
  location_in_person: string | null
  location_virtual: string | null
  community: string
  community_type: TCommunityType
  registered: boolean
  description: string
  time?: string
  duration?: number
  invitable: boolean
  submit_questions: boolean
  cohosts?: { name: string; id: number; type: TCommunityType }[] | null
  hosts: IEventCallHost[]
  is_recurring: boolean
  waitlist_open: boolean
  waitlisted: boolean
  remaining_spots?: number | null
  days_to_deadline?: number | null
  registerable?: boolean
  status?: TStatus
  status_pretty?: string | null
  subscribed_to_host: boolean
  invited_users_count?: number
  location_url?: string
  allies_welcome?: boolean
  closed_at?: string
}

export interface ISpaeker {
  id: number
  name: string
  title: string
  company: string
  bio: string
  avatar: string
  file_name: string
  errors?: any
}

export interface IEventCallHost {
  name: string
  subtitle: string
  id: number
  logo: string
  logo_large: string
}

export type TBadge =
  | 'supporter'
  | 'advocate'
  | 'conversation_starter'
  | 'thought_leader'
  | 'volunteer'
  | 'delegate'
  | 'connector'
  | 'ambassador'
  | 'community_leader'
  | 'intersectional_leader'

export type TActionLocation = 'card' | 'table-cell' | 'banner'

export interface IUser {
  id: number
  first_name: string
  last_name: string
  full_name?: string
  full_name_html?: string
  logo_url: string | null
  email: string
  pronouns?: string
}

export interface IYearbookMember extends IUserObject {
  avatar_url?: string
  initial?: string
}

export interface IApplicant extends IUser {
  application_id?: number
  title?: string
  department?: string
  location?: string
}

export interface INotYetMatchedApplicant extends IApplicant {
  manually_match_url: string
  regenerate_reason_from_ids_url: string
  remove_url: string
  prioritization: boolean
  boost_field?: string
}

export interface IBoostableObject {
  id?: number
  location?: string
  boost_field?: string
  user_boost_field?: string
}

export interface IMatchRemovedApplicant extends IApplicant {
  removed_by?: string
  removed_note?: string
  restore_url: string
  update_removed_note_url: string
}

export interface DocumentMetadata {
  filename: string
  mime_type: string
  size: number
  storage: string
}

export enum ResourceType {
  Document,
  Link,
}

export interface Resource<ResourceType> {
  id: string
  title: string
  created_at: string
  updated_at: string
  internal: boolean
  url: string
  link_url: string | null
  resource_type: ResourceType
  resourceable_type: string
  document_data: { id: string; metadata: DocumentMetadata } | null
}

export interface User {
  id: number
  first_name: string
  last_name: string
  full_name?: string
  title?: string
  department?: string
  active: boolean
  organization_id?: number
  organization_admin: boolean
  super_admin: boolean
  user_badges_count: number
  email: string
  created_at: string
  updated_at?: string
  suspended_at?: string
  employee_id?: number
  timezone: string
  pronouns?: string
  avatar_data?: any
  avatar_url?: string
  location?: string
}

export interface Context {
  id: number
  name: string
  active: boolean
}

export type TTimezone = {
  tzid: string
  abbreviation: string
  offset_string: string
  utc_offset: number
}

export interface ITeamMember {
  fake_id: string | undefined
  id?: number
  user?: IUserObject
  role?: string
  _destroy: boolean
  errors?: Record<string, string[]>
  avatar_url?: string
  initial?: string
}

export interface ISessionMeta {
  date: string
  time_from: string
  time_to: string
  tz: string
}

export interface ISessionsMeta {
  sessions: ISessionMeta[]
}

export interface IProgramNextStep {
  // Icon name for next step
  icon: string
  // HTML string that represents the description for next step
  description: string
}

export interface IProgramSummary {
  current_program: SimpleMentorshipProgram
  me: MentorshipApplicant
  my_partner: MentorshipApplicant
  reasoning: string
  next_steps: IProgramNextStep[]
  cohort: ICommunity
  additional_leaders: ITeamMember[]
  program_display_detail_options?: Record<string, boolean>
}

export interface MentorshipApplicant extends User {
  application_csv?: string
  reasoning?: string
}

export interface SimpleMentorshipProgram {
  id: number
  name: string
  full_name: string
  details_ajax_url?: string
}

export interface MentorshipProgram extends SimpleMentorshipProgram {
  open_date: string
  open_time: string
  open_timezone: string
  deadline_date: string
  deadline_time: string
  deadline_timezone: string
  creator_id: number
  published_at: string
  sessions_meta: unknown
  status: number
  created_at: string
  updated_at: string
  leaders: ITeamMember[]
}

export interface IMentorshipExchange {
  id: number
  name: string
  full_name?: string
  full_name_with_index?: string
  status: TMentorshipExchangeStatus
  matching_status: TMentorshipExchangeMatchingStatus
  notification_status: TMentorshipExchangeNotificationStatus
  open_date: string
  open_time: string
  open_date_time: Date
  open_timezone: string
  open_timezone_abbr: string
  deadline_date: string
  deadline_time: string
  deadline_date_time: Date
  deadline_timezone: string
  deadline_timezone_abbr: string
  accepting_applications: boolean
  closed: boolean
  created_at: Date
  updated_at?: Date
  creator_id: number
  published_at?: Date
  sessions_meta: ISessionsMeta
  actions: string
  manager_approval_required?: boolean
  budget_approval_required?: boolean
  last_manager_approval_reminder_sent_at?: string
  group?: ICommunity
  display_detail_options?: Record<string, boolean>
}

export interface IMentorshipExchangePackage {
  data: IMentorshipExchange
  programs: IMentorshipExchange[]
  link?: string
}

export interface IUserTruncated {
  id: string
  full_name: string
  email: string
  title: string
  department: string
  errors?: any
}

export interface IMentorshipExchangeApplicationActions {
  prioritize?: boolean
  remove?: boolean
  edit_prioritize_notes?: boolean
  deprioritize?: boolean
  edit_remove_notes?: boolean
  restore?: boolean
  boost_field?: boolean
  boost_field_edit?: boolean
}

export interface IMentorshipExchangeApplication {
  id: number
  user_id: number
  name: string
  email: string
  boost_field?: string
  title?: string
  department?: string
  location?: string
  submitted_at: Date
  prioritized: boolean
  eligible: boolean
  prioritized_note?: string
  prioritized_by?: IUserTruncated
  removed_note?: string
  removed_by?: IUser
  actions: IMentorshipExchangeApplicationActions
}

export type TFile = {
  id: number
  file_name: string
}

export interface IMentorshipCsvUploaderSettings {
  label: string
  name: string
  deleteInputName: string
  initialValue?: TFile
  cachedData?: string
  error?: string
}

export type MentorshipExchangeDotMenuTypes = 'pre-matching' | 'post-matching'

export interface IAudienceCount {
  audienceCount: {
    matched: number
    not_matched: number
  }
}

export interface IAudienceCountsByType {
  filters: IAudienceCount
  csvFile: IAudienceCount
  sendToAll?: IAudienceCount
}
